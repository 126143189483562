import React from "react";

import * as utils from "utils/utils";

import { Route, Redirect, RouteProps } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {
	addLoggedInContext: boolean;
}

export default React.memo((props: ProtectedRouteProps): JSX.Element => {
	const { addLoggedInContext, component: UIComponent, ...rest } = props;

	if (UIComponent) {
		// if login is not required in route, we just add focus context provider
		if (!addLoggedInContext) {
			return <Route {...rest} render={(props) => <UIComponent {...props} key={Date.now()} />} />;
		} else {
			// These context values are used to tell the current logged in user
			const isUserLoggedIn = utils.isUserLoggedIn();
			if (isUserLoggedIn) {
				return <Route {...rest} render={(props) => <UIComponent {...props} key={Date.now()} />} />;
			} else {
				return <Route {...rest} render={() => <Redirect to={{ pathname: "/login" }} />} />;
			}
		}
	}
	return <Route {...rest} render={() => <Redirect to={{ pathname: "/login" }} />} />;
});
