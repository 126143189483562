import { useState, useEffect } from "react";
import { Field, FieldAttributes } from "formik";
import { Autocomplete } from "formik-material-ui";

import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";

import TextField from "components/atom/TextFieldFocus";

import "./AutoComplete.scss";
import * as AutoCompleteUtils from "./AutoCompleteUtils";

interface TextFieldInputProps extends FieldAttributes<any> {
	id: string;
	focusSectionName: string;
	name: string;
	type: string;
	placeholder: string;
	formikValue: string | number;
	inputRef: React.RefObject<HTMLInputElement>;
	setFieldValue: (field: string, value: any) => void;
	onKeyDown: (event: any) => void;
	label?: string;
	currFocusedSection: React.MutableRefObject<string>;
	currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined>;
	onKeyUp?: (event: any) => void;
	runOnInputChange?: (value: string) => void;
}

export default function TextFieldInput(props: TextFieldInputProps): JSX.Element {
	const [inputValue, setInputValue] = useState<string>("");
	const classes = AutoCompleteUtils.useStyles();

	useEffect(() => {
		if (props.runOnInputChange) {
			// this is used for auto searching on typing
			if (inputValue !== null && inputValue !== undefined) {
				props.runOnInputChange(inputValue);
			}
		}
	}, [inputValue]);

	return (
		<Field
			component={Autocomplete}
			name={props.name}
			size="small"
			freeSolo
			disableClearable
			options={[]}
			open={false}
			popupIcon={null}
			inputValue={inputValue}
			onInputChange={(event: React.SyntheticEvent, value: string, reason: string) => {
				setInputValue(value);
			}}
			onBlur={(event: any) => {
				const textValue = event?.target?.value;
				if (props.formikValue.toString() !== textValue) {
					if (textValue !== null && textValue !== undefined) {
						props.setFieldValue(props.name, textValue);
					}
				}
			}}
			getOptionLabel={(option: string | number) => option.toString()}
			renderInput={(params: AutocompleteRenderInputParams) => (
				<div className="TextField-border-radius">
					<TextField
						{...params}
						inputId={props.id}
						inputRef={props.inputRef}
						type={props.type}
						focusSectionName={props.focusSectionName}
						currFocusedSection={props.currFocusedSection}
						currFocusedHTMLElementRef={props.currFocusedHTMLElementRef}
						label={props.label}
						className={classes.AutoCompleteFreeSoloInput_MainInput_inputBoxAllBorders}
						placeholder={props.placeholder}
						InputProps={{
							...params.InputProps,
						}}
						onKeyDown={(event: any) => props.onKeyDown(event)}
						onKeyUp={(event: any) => {
							if (props.onKeyUp) {
								props.onKeyUp(event);
							}
						}}
					/>
				</div>
			)}
			onChange={(e: React.SyntheticEvent, value: string | null | undefined) => {
				// setting the field value manually
				props.setFieldValue(props.name, value);
			}}
			classes={{ input: "AutoCompleteText" }}
		/>
	);
}
