import React, { createContext, useRef } from "react";

export interface ITodo {
	currFocusedSection: React.MutableRefObject<string>;
	currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined>;
}

// Provider and Consumer are connected through their "parent" context
export const FocusContext = createContext({} as ITodo);

export interface ConfigProviderProps {
	children: React.ReactElement<any, any>;
}

// Provider will be exported wrapped in ConfigProvider component.
export function FocusContextProvider(props: ConfigProviderProps) {
	// These context values are used to tell where the focus is currently and in which section
	const currFocusedHTMLElementRef = useRef<React.RefObject<HTMLElement>>();
	const currFocusedSection = useRef<string>("");
	return (
		<FocusContext.Provider
			value={{
				currFocusedSection: currFocusedSection,
				currFocusedHTMLElementRef: currFocusedHTMLElementRef,
			}}
		>
			{props.children}
		</FocusContext.Provider>
	);
}
