import React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import * as utils from "utils/utils";
import { SnackbarPropsType } from "types/server";

export interface ComponentProps {
	snackbarProps: SnackbarPropsType;
	setSnackbarProps: any;
	disableAutoHide?: boolean;
}

export const ShowSnackbar = (message: string, severity: AlertColor, setSnackbarProps: (obj: SnackbarPropsType) => void) => {
	setSnackbarProps({ show: true, message: message, severity: severity });
};

export default ({ snackbarProps, setSnackbarProps, disableAutoHide }: ComponentProps): JSX.Element => {
	const autoHideDuration_duration = disableAutoHide ? null : 6000;
	const handleClose = (event?: any, reason?: SnackbarCloseReason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarProps((prevState: SnackbarPropsType) => ({
			...prevState,
			show: false,
		}));
	};
	return (
		<Snackbar open={snackbarProps["show"]} autoHideDuration={autoHideDuration_duration} onClose={handleClose}>
			<Alert variant="filled" onClose={handleClose} severity={snackbarProps["severity"]} sx={{ width: "100%" }}>
				<AlertTitle sx={{ textAlign: "left" }}>{utils.capitalizeWordsInString(snackbarProps["severity"])}</AlertTitle>
				{snackbarProps["message"]}
			</Alert>
		</Snackbar>
	);
};
