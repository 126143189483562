import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { BackdropPropsType } from "types/server";

export interface ComponentProps {
	backdropProps: BackdropPropsType;
	setBackdropProps: (obj: BackdropPropsType) => void;
}

export const ShowBackdrop = (setBackdropProps: (obj: BackdropPropsType) => void): void => {
	setBackdropProps({ show: true });
};

export const HideBackdrop = (setBackdropProps: (obj: BackdropPropsType) => void): void => {
	setBackdropProps({ show: false });
};

export default ({ backdropProps }: ComponentProps): JSX.Element => {
	return (
		<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropProps["show"]}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
