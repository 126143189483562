import { useHistory } from "react-router-dom";
import React, { useContext, Fragment, useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "components/atom/CardActionArea";

import { FocusContext } from "app/focusConfigContext";

import logger from "utils/logger";
import * as utils from "utils/utils";
import { SectionNavigationType, CardListNavigationType } from "types/common";

interface CardListNavigationPropsType {
	focusSectionName: string;
	cardLists: CardListNavigationType[];
	bringFocusToCurrentSection?: SectionNavigationType;
}

export default function CardListNavigation({ focusSectionName, cardLists, bringFocusToCurrentSection }: CardListNavigationPropsType): JSX.Element {
	const history = useHistory();
	const focusCtx = useContext(FocusContext);

	const [navigationDFAState, setNavigationDFAState] = useState<React.RefObject<HTMLButtonElement>[]>([]);

	useEffect(() => {
		const tmp_ref: React.RefObject<HTMLButtonElement>[] = [];
		for (let index = 0; index < cardLists.length; index++) {
			tmp_ref.push(React.createRef<HTMLButtonElement>());
		}
		setNavigationDFAState(tmp_ref);
		if (focusCtx?.currFocusedSection?.current === focusSectionName) {
			utils.focusIn(tmp_ref[0], focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
		}
		logger.info("components/organism/CardListNavigation/index:useEffect => created navigation DFA : ", tmp_ref);
	}, [cardLists.length]);

	useEffect(() => {
		if (focusCtx?.currFocusedSection?.current === focusSectionName) {
			if (navigationDFAState.length === cardLists.length) {
				utils.focusIn(navigationDFAState[0], focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
			}
		}
	}, [navigationDFAState]);

	// useEffect for focusing on the section selected
	useEffect(() => {
		// since in this component we setNavigationDFAState we need to manually set the section so that
		// on next re-render the focus can be brought
		// manually changing section name outside the if condition so that re-rendering happens

		if (bringFocusToCurrentSection) {
			if (bringFocusToCurrentSection["value"] > 0) {
				focusCtx.currFocusedSection.current = focusSectionName;
			}

			if (navigationDFAState.length > 0) {
				utils.handleSectionBroughtToFocus(
					bringFocusToCurrentSection,
					focusSectionName,
					navigationDFAState[0],
					navigationDFAState[navigationDFAState.length - 1],
					focusCtx.currFocusedSection,
					focusCtx.currFocusedHTMLElementRef
				);
			}
		}
	}, [bringFocusToCurrentSection?.value, navigationDFAState]);

	const cardKeyNavigationPressFunction = (event: any, currIndex: number, cardLists_param: CardListNavigationType[]) => {
		const keyPressed = event?.key;

		if (keyPressed === "ArrowDown") {
			utils.focusIn(
				navigationDFAState[utils.getCircularNextIndexInArray(currIndex, cardLists_param.length)],
				focusSectionName,
				focusCtx.currFocusedSection,
				focusCtx.currFocusedHTMLElementRef
			);
			event.preventDefault();
		} else if (keyPressed === "ArrowUp") {
			utils.focusIn(
				navigationDFAState[utils.getCircularPrevIndexInArray(currIndex, cardLists_param.length)],
				focusSectionName,
				focusCtx.currFocusedSection,
				focusCtx.currFocusedHTMLElementRef
			);
			event.preventDefault();
		}

		cardLists_param.forEach((card: CardListNavigationType, index: number) => {
			if (card["keyboardShortcut"]) {
				if (keyPressed === card["keyboardShortcut"].toLowerCase() || keyPressed === card["keyboardShortcut"].toUpperCase()) {
					utils.focusIn(navigationDFAState[index], focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
					setTimeout(() => {
						simulateClick(card);
					}, 10);
				}
			}
		});
	};

	const simulateClick = (card: CardListNavigationType) => {
		if (card?.onClickNavigateURL) {
			history.push(card.onClickNavigateURL);
		} else if (card?.onClickFunc) {
			card.onClickFunc();
		}
	};

	return (
		<Fragment>
			{navigationDFAState.length === cardLists.length && (
				<Fragment>
					{cardLists.map((card: CardListNavigationType, index: number) => (
						<Card key={index} elevation={5} raised sx={{ p: 0, m: 0 }}>
							<CardActionArea
								id={`${focusSectionName}-${index}`}
								cardRef={navigationDFAState[index]}
								focusSectionName={focusSectionName}
								currFocusedSection={focusCtx.currFocusedSection}
								currFocusedHTMLElementRef={focusCtx.currFocusedHTMLElementRef}
								classes={{
									root: "SelectedCardDetails",
								}}
								onClick={() => {
									simulateClick(card);
								}}
								onKeyDown={(event: any) => {
									cardKeyNavigationPressFunction(event, index, cardLists);
								}}
							>
								<CardContent>
									<Grid container>
										<Grid item xs={12}>
											<Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
												{card["ImgSource"]}
												<Typography variant="body2" sx={{ fontSize: "110%" }}>
													{card["SectionTitle"]}
												</Typography>
											</Stack>
										</Grid>
									</Grid>
								</CardContent>
							</CardActionArea>
						</Card>
					))}
				</Fragment>
			)}
		</Fragment>
	);
}
