/* All utils assume that the input has been validated before sending to them */
import React from "react";
import constants from "constants/constants";

import { SectionNavigationType } from "types/common";
import { SnackbarPropsType, BackdropPropsType } from "types/server";

export function trim(str: string): string {
	if (str) {
		return str.trim();
	}
	return "";
}

export function trim_toUpperCase(str: string): string {
	if (str) {
		return str.trim().toUpperCase();
	}
	return "";
}

export function trim_toLowerCase(str: string): string {
	if (str) {
		return str.trim().toLowerCase();
	}
	return "";
}

export function capitalizeWordsInString(str: string): string {
	return str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

export function capitalizeFirstWordInString(str: string): string {
	return str.trim().charAt(0).toUpperCase() + str.trim().slice(1);
}

// function used to focus based on context values
export function focusIn(
	ref: React.RefObject<HTMLElement> | undefined,
	focusSectionName: string,
	currFocusedSection: React.MutableRefObject<string> | undefined,
	currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined> | undefined
) {
	if (ref && currFocusedHTMLElementRef && currFocusedSection) {
		currFocusedSection.current = focusSectionName;
		currFocusedHTMLElementRef.current = ref;
		ref.current?.focus();

		// if the HTML element is input we move cursor to last element
		if (ref.current && "value" in ref.current && "type" in ref.current) {
			const val: string = ref.current["value"];
			if (val) {
				// since setSelectionRange is not supported for number input
				if (["text", "search", "url", "tel", "password"].indexOf(ref.current["type"]) >= 0) {
					setTimeout(function () {
						if (ref.current && "setSelectionRange" in ref.current) {
							(ref.current["setSelectionRange"] as (a: number, b: number) => void)(val.length + 1, val.length + 1);
						}
					}, 0);
				}
			}
		}
	}
}

// function used when focus needs to be reset -> used when pop-over is open/closed
export function resetHTMLElementRefFocus(currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined> | undefined) {
	if (currFocusedHTMLElementRef) {
		currFocusedHTMLElementRef.current = undefined;
	}
}

// function used when focus needs to be reset -> used when pop-over is open/closed
export function resetFocus(
	currFocusedSection: React.MutableRefObject<string> | undefined,
	currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined> | undefined
) {
	if (currFocusedSection && currFocusedHTMLElementRef) {
		currFocusedSection.current = "";
		currFocusedHTMLElementRef.current = undefined;
	}
}

/**
 * Boilerplate code for loading states used when fetching data from server on page load
 * @returns
 */
export function intializePageLoadingStates(): any {
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [isLoadingError, setIsLoadingError] = React.useState<boolean>(false);
	const [snackbarProps, setSnackbarProps] = React.useState<SnackbarPropsType>({
		show: false,
		message: "",
		severity: "success",
	});
	const [backdropProps, setBackdropProps] = React.useState<BackdropPropsType>({
		show: false,
	});
	return [isLoading, setIsLoading, isLoadingError, setIsLoadingError, snackbarProps, setSnackbarProps, backdropProps, setBackdropProps];
}

/**
 * getCircularNextIndexInArray is used in dashboard section card navigation
 * when scroll reaches last card, to auto shift it to the 1st card, we implemenet circular functions
 * @param index
 * @param length
 * @returns
 */
export function getCircularNextIndexInArray(index: number, length: number): number {
	return (index + 1) % length;
}
export function getCircularPrevIndexInArray(index: number, length: number): number {
	return (index - 1 + length) % length;
}

/**
 * handleSectionBroughtToFocus is used for sections inside popover forms.
 * This function is called when a section needs to be focused on in a popover form
 * @param bringFocusToCurrentSection bringToFocus value which changes when we want to focus
 * @param focusSectionName section name
 * @param topRef ref to focus if it is focused from top
 * @param bottomRef ref to focus if it is focused from bottom
 * @param currFocusedSection values obtained from context
 * @param currFocusedHTMLElementRef  values obtained from context
 */
export function handleSectionBroughtToFocus(
	bringFocusToCurrentSection: SectionNavigationType,
	focusSectionName: string,
	topRef: React.RefObject<HTMLElement> | undefined,
	bottomRef: React.RefObject<HTMLElement> | undefined,
	currFocusedSection: React.MutableRefObject<string> | undefined,
	currFocusedHTMLElementRef: React.MutableRefObject<React.RefObject<HTMLElement> | undefined> | undefined
) {
	if (bringFocusToCurrentSection["value"] > 0) {
		switch (bringFocusToCurrentSection["focus"]) {
			case "top":
				focusIn(topRef, focusSectionName, currFocusedSection, currFocusedHTMLElementRef);
				break;

			case "bottom":
				focusIn(bottomRef, focusSectionName, currFocusedSection, currFocusedHTMLElementRef);
				break;
		}
	}
}

// Check if user is logged in. Return user details from localstorage.
export function isUserLoggedIn(): boolean {
	const auth_token = localStorage.getItem(constants.AUTH_TOKEN_KEY);
	if (auth_token) {
		return true;
	} else {
		return false;
	}
}

/**
 * used in examinationForm navigate across sections
 * @param index
 * @param mode
 * @param setMoveToSection
 */
export function moveFocusToSection(
	index: number,
	mode: "top" | "bottom",
	setMoveToSection: (s: (t: SectionNavigationType[]) => SectionNavigationType[]) => void
): void {
	setMoveToSection((curr_val: SectionNavigationType[]) => {
		const new_val = [...curr_val];
		new_val[index] = { focus: mode, value: curr_val[index].value + 1 };
		return new_val;
	});
}
