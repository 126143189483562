import { useHistory } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useContext, useState, useEffect, Fragment } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { FocusContext } from "app/focusConfigContext";
import Snackbar, { ShowSnackbar } from "components/atom/Snackbar";
import Backdrop, { ShowBackdrop, HideBackdrop } from "components/atom/Backdrop";
import TextFieldInput from "components/molecule/InputComponents/AutoComplete/TextFieldInput";

import * as api from "utils/api";
import logger from "utils/logger";
import * as utils from "utils/utils";
import constants from "constants/constants";

import { SnackbarPropsType, BackdropPropsType } from "types/server";

type FormikFormInputParamTypes = {
	email: string;
	password: string;
};

export default function Login(): JSX.Element {
	const history = useHistory();
	const focusCtx = useContext(FocusContext);
	const focusSectionName = "login_page";

	const [snackbarProps, setSnackbarProps] = useState<SnackbarPropsType>({
		show: false,
		message: "",
		severity: "success",
	});
	const [backdropProps, setBackdropProps] = useState<BackdropPropsType>({
		show: false,
	});
	const [emailInputRef, setEmailInputRef] = useState<React.RefObject<HTMLInputElement>>(React.createRef<HTMLInputElement>());
	const [passwordInputRef, setPasswordInputRef] = useState<React.RefObject<HTMLInputElement>>(React.createRef<HTMLInputElement>());
	const [submitButtonRef, setSubmitButtonRef] = useState<React.RefObject<HTMLButtonElement>>(React.createRef<HTMLButtonElement>());

	useEffect(() => {
		// only move to focus if the current section is in focus
		if (focusCtx.currFocusedSection?.current === focusSectionName) {
			focusCtx.currFocusedHTMLElementRef?.current?.current?.focus();
		}
	});

	useEffect(() => {
		setEmailInputRef(React.createRef<HTMLInputElement>());
		setPasswordInputRef(React.createRef<HTMLInputElement>());
		setSubmitButtonRef(React.createRef<HTMLButtonElement>());
		utils.focusIn(emailInputRef, focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
	}, []);

	const handleSubmit = (values: FormikFormInputParamTypes, { setSubmitting }: FormikHelpers<FormikFormInputParamTypes>) => {
		setSubmitting(false);

		logger.info("pages/Login/index:handleSubmit => Starting submission of data : ", values);
		const submittedValues = {
			email: utils.trim_toLowerCase(values["email"]),
			password: utils.trim(values["password"]),
		};

		if (!submittedValues["email"]) {
			utils.focusIn(emailInputRef, focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
			return;
		} else if (!submittedValues["password"]) {
			utils.focusIn(passwordInputRef, focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
			return;
		} else {
			type successFullLoginType = {
				token_id: string;
			};
			logger.info("pages/Login/index:handleSubmit => After Parsing obtained data : ", submittedValues);
			ShowBackdrop(setBackdropProps);
			setSubmitting(true);

			api.Post(`/api/v1/login`, submittedValues).then(
				(res: any) => {
					const succ_login_res = res.data.data as successFullLoginType;
					ShowSnackbar("Successfully Logged In", "success", setSnackbarProps);
					logger.info("pages/Login/index:handleSubmit => Success Logged in : ", succ_login_res);
					localStorage.setItem(constants.AUTH_TOKEN_KEY, succ_login_res["token_id"]);

					HideBackdrop(setBackdropProps);
					setSubmitting(false);

					// redirecting after small delay
					setTimeout(function () {
						history.push("/dashboard");
					}, 1000);
				},
				(err: any) => {
					logger.error("pages/Login/index:handleSubmit => Error in creating examination : ", err?.response);
					ShowSnackbar(err?.response?.data?.message, "error", setSnackbarProps);
					HideBackdrop(setBackdropProps);
					setSubmitting(false);
					utils.focusIn(emailInputRef, focusSectionName, focusCtx.currFocusedSection, focusCtx.currFocusedHTMLElementRef);
				}
			);
		}
	};

	return (
		<Grid component="main">
			<CssBaseline />
			<Box sx={{ display: "flex" }}>
				<Box
					component="main"
					sx={{
						backgroundColor: "grey.100",
						flexGrow: 1,
						height: "100vh",
						overflow: "auto",
						pl: 0.75,
						pt: 10,
					}}
				>
					<Fragment>
						<Formik
							initialValues={{
								email: "",
								password: "",
							}}
							onSubmit={handleSubmit}
						>
							{({ values, submitForm, setFieldValue, isSubmitting, handleSubmit }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Grid container component="main">
										<CssBaseline />
										<Grid item xs={4} />
										<Grid item xs={4} component={Paper} elevation={6} square sx={{ p: 5 }}>
											<Fragment>
												{isSubmitting && <LinearProgress />}
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
														<LockOutlinedIcon />
													</Avatar>
													<Typography component="h1" variant="h5">
														Log in
													</Typography>
													<Fragment>
														{emailInputRef && passwordInputRef && submitButtonRef && (
															<Box component="div" sx={{ mt: 1 }}>
																<Grid container spacing={3}>
																	<Grid item xs={3}>
																		<Typography variant="body2">Email</Typography>
																	</Grid>
																	<Grid item xs={9}>
																		<TextFieldInput
																			id="email"
																			inputRef={emailInputRef}
																			focusSectionName={focusSectionName}
																			currFocusedSection={focusCtx.currFocusedSection}
																			currFocusedHTMLElementRef={focusCtx.currFocusedHTMLElementRef}
																			name="email"
																			margin="normal"
																			type="text"
																			placeholder="Enter Email"
																			formikValue={values["email"]}
																			setFieldValue={setFieldValue}
																			onKeyDown={(event: any) => {
																				const keyPressed = event?.key;

																				switch (true) {
																					case keyPressed === "ArrowDown" || keyPressed === "Enter":
																						utils.focusIn(
																							passwordInputRef,
																							focusSectionName,
																							focusCtx.currFocusedSection,
																							focusCtx.currFocusedHTMLElementRef
																						);
																						event.preventDefault();
																						break;

																					default:
																						break;
																				}
																			}}
																		/>
																	</Grid>

																	<Grid item xs={3}>
																		<Typography variant="body2">Password</Typography>
																	</Grid>
																	<Grid item xs={9}>
																		<TextFieldInput
																			id="password"
																			inputRef={passwordInputRef}
																			focusSectionName={focusSectionName}
																			currFocusedSection={focusCtx.currFocusedSection}
																			currFocusedHTMLElementRef={focusCtx.currFocusedHTMLElementRef}
																			name="password"
																			margin="normal"
																			placeholder="Enter Password"
																			type="password"
																			formikValue={values["password"]}
																			setFieldValue={setFieldValue}
																			onKeyDown={(event: any) => {
																				const keyPressed = event?.key;

																				switch (true) {
																					case keyPressed === "ArrowUp":
																						utils.focusIn(
																							emailInputRef,
																							focusSectionName,
																							focusCtx.currFocusedSection,
																							focusCtx.currFocusedHTMLElementRef
																						);
																						event.preventDefault();
																						break;

																					case keyPressed === "ArrowDown":
																						utils.focusIn(
																							submitButtonRef,
																							focusSectionName,
																							focusCtx.currFocusedSection,
																							focusCtx.currFocusedHTMLElementRef
																						);
																						event.preventDefault();
																						break;

																					case keyPressed === "Enter":
																						submitForm();
																						event.preventDefault();
																						break;
																				}
																			}}
																		/>
																	</Grid>
																	<Grid item xs={3}></Grid>
																	<Grid item xs={9}>
																		<LoadingButton
																			ref={submitButtonRef}
																			loading={isSubmitting}
																			disabled={isSubmitting}
																			onClick={() => {
																				submitForm();
																			}}
																			variant="contained"
																			color="warning"
																			fullWidth
																			size="small"
																		>
																			Submit
																		</LoadingButton>
																	</Grid>
																</Grid>
															</Box>
														)}
													</Fragment>
												</Box>
											</Fragment>
										</Grid>
										<Grid item xs={4} />
									</Grid>
								</Form>
							)}
						</Formik>
						<Backdrop backdropProps={backdropProps} setBackdropProps={setBackdropProps} />
						<Snackbar snackbarProps={snackbarProps} setSnackbarProps={setSnackbarProps} />
					</Fragment>
				</Box>
			</Box>
		</Grid>
	);
}
