export default {
	AUTH_TOKEN_KEY: "AUTH_TOKEN_KEY",
	COLORS: {
		BORDER_COLOR: "#949595",
		INPUT_FOCUSED_COLOR: "#FFFF80",
		DARK_GREY_TEXT_COLOR: "#949595",
	},

	SECTION_NAME: {
		LOGIN_PAGE: "login",
	},
};
