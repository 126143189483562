import { useContext, Fragment, useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { FocusContext } from "app/focusConfigContext";
import CardListNavigation from "components/organism/CardListNavigation";
import EmptyTopBar from "components/organism/NavigationTopSideBar/EmptyTopBar";

import * as utils from "utils/utils";

import { SectionNavigationType } from "types/common";

export default function Dashboard(): JSX.Element {
	const pageName = "dashboard";
	const focusCtx = useContext(FocusContext);

	const [moveToSection, setMoveToSection] = useState<SectionNavigationType[]>([{ focus: "top", value: 1 }]);
	const [expanded, setExpanded] = useState<boolean>(true);

	useEffect(() => {
		// for popover logic before re-rendering we change focsCtx to current section name
		focusCtx.currFocusedSection.current = pageName;
	}, []);

	useEffect(() => {
		if (expanded) {
			// for popover logic before re-rendering we change focsCtx to current section name
			utils.moveFocusToSection(0, "top", setMoveToSection);
		}
	}, [expanded]);

	return (
		<Grid component="main">
			<CssBaseline />
			<Box sx={{ display: "flex" }}>
				<EmptyTopBar />
				<Box
					component="main"
					sx={{
						backgroundColor: "grey.100",
						flexGrow: 1,
						height: "100vh",
						overflow: "auto",
						pl: 0.75,
					}}
				>
					<Fragment>
						{moveToSection.length > 0 && (
							<Stack direction="row" spacing={1} sx={{ pt: 9 }}>
								<Paper elevation={4} sx={{ width: "22vw", height: "80vh", p: 0, pt: 2, backgroundColor: "white" }}>
									<img src="/static/img/onito-technologies.jpg" alt="Onito Technologies" width="70%" />
								</Paper>
								<Paper elevation={4} sx={{ width: "63vw", height: "80vh", p: 0, pt: 7, backgroundColor: "transparent" }}>
									<Grid container spacing={2}>
										<Grid item xs={3}></Grid>
										<Grid item xs={6}>
											<Stack direction="column" spacing={1.5}>
												<Accordion
													expanded={expanded}
													onChange={() => {
														setExpanded((e: boolean) => !e);
													}}
													sx={{ backgroundColor: "grey.300" }}
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls="receptiona-content"
														id="receptiona-header"
													>
														<Typography>Onito OPD</Typography>
													</AccordionSummary>
													<AccordionDetails>
														<Stack direction="column" spacing={5}>
															<CardListNavigation
																focusSectionName={pageName}
																cardLists={[
																	// {
																	// 	ImgSource: (
																	// 		<img src="/static/img/win10-logo.png" width="64px" height="64px" />
																	// 	),
																	// 	SectionTitle: (
																	// 		<span>
																	// 			Download Onito OPD Application <br />
																	// 			<span style={{ fontSize: 15 }}>
																	// 				<b>(Windows 10, 64-bit)</b>
																	// 			</span>
																	// 		</span>
																	// 	),
																	// 	keyboardShortcut: "",
																	// 	onClickFunc: () => {
																	// 		window.open(
																	// 			"https://storage.googleapis.com/onito-335906.appspot.com/downloads/onito-opd/win10/x64/onito-opd-win10-x64.exe",
																	// 			"_blank"
																	// 		);
																	// 	},
																	// },
																	{
																		ImgSource: (
																			<img src="/static/img/win10-logo.png" width="64px" height="64px" />
																		),
																		SectionTitle: (
																			<span>
																				Download Onito OPD Application <br />
																				<span style={{ fontSize: 15 }}>
																					<b>(Windows 10, 32 / 64 bit)</b>
																				</span>
																			</span>
																		),
																		keyboardShortcut: "",
																		onClickFunc: () => {
																			window.open(
																				"https://storage.googleapis.com/onito-335906.appspot.com/downloads/onito-opd/win10/x86/onito-opd-win10-x86.exe",
																				"_blank"
																			);
																		},
																	},
																	{
																		ImgSource: <img src="/static/img/win7-logo.png" width="64px" height="64px" />,
																		SectionTitle: (
																			<span>
																				Download Onito OPD Application <br />
																				<span style={{ fontSize: 15 }}>
																					<b>(Windows 7, 32 / 64 bit)</b>
																				</span>
																			</span>
																		),
																		keyboardShortcut: "",
																		onClickFunc: () => {
																			window.open(
																				"https://storage.googleapis.com/onito-335906.appspot.com/downloads/onito-opd/win7/x86-64/onito-opd-win7-x86.exe",
																				"_blank"
																			);
																		},
																	},
																]}
																bringFocusToCurrentSection={moveToSection[0]}
															/>
														</Stack>
													</AccordionDetails>
												</Accordion>
											</Stack>
										</Grid>
										<Grid item xs={3}></Grid>
									</Grid>
								</Paper>
								<Paper elevation={4} sx={{ width: "22vw", height: "80vh", p: 0, pl: 1, pt: 2, backgroundColor: "white" }}>
									{/* <Typography sx={{ fontWeight: 550, fontSize: 15, textAlign: "left" }}>Dr. Arti Luthra</Typography>
									<Typography sx={{ fontWeight: 500, fontSize: 15, textAlign: "left" }}>
										<a href="mailto:drartiluthra@gmail.com">drartiluthra@gmail.com</a>
									</Typography>
									<Typography sx={{ fontWeight: 500, fontSize: 13.5, textAlign: "left" }}>
										License Policy: <strong>3-Month Free Trial (Onito OPD)</strong>
									</Typography> */}
								</Paper>
							</Stack>
						)}
					</Fragment>
				</Box>
			</Box>
		</Grid>
	);
}
