import { Fragment } from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import "./App.scss";

import ProtectedRoute from "components/atom/ProtectedRoute";

import LoginPage from "pages/Login";
import DashboardPage from "pages/Dashboard";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const mdTheme = createTheme({
	palette: {
		mode: "light",
	},
});

const styles = {};

function App(): JSX.Element {
	return (
		<div className="App" style={styles}>
			<Fragment>
				<ThemeProvider theme={mdTheme}>
					<BrowserRouter>
						<Switch>
							<ProtectedRoute addLoggedInContext={false} exact path="/login" component={LoginPage} />
							<ProtectedRoute addLoggedInContext={true} exact path="/dashboard" component={DashboardPage} />
							<Route path="/" render={() => <Redirect to={{ pathname: "/dashboard" }} />} />;
						</Switch>
					</BrowserRouter>
				</ThemeProvider>
			</Fragment>
		</div>
	);
}

export default App;
