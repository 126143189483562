import axios, { AxiosResponse } from "axios";

import logger from "utils/logger";

const AXIOS_TIMEOUT = 20000;

const axiosInstance = axios.create({
	timeout: AXIOS_TIMEOUT,
	headers: {
		post: {
			"Content-Type": "application/json",
		},
		get: {
			"Content-Type": "application/json",
		},
	},
});

/**
 * Axios AJAX functions without JWT token logic
 */
export function Get(url: string, params: any): Promise<AxiosResponse<any>> {
	// eslint-disable-next-line
	const config: any = { params: params }; //setting the GET params
	logger.debug(`utils:api:Get => sending GET request: ${url} , params: `, params);

	return axiosInstance.get(url, config);
}

export function Post(url: string, values: any): Promise<AxiosResponse<any>> {
	logger.debug(`utils:api:Post => sending POST request: ${url}, values: `, values);

	return axiosInstance.post(url, values, {
		headers: {},
	});
}
