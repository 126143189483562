import React from "react";
import TextField from "@mui/material/TextField";

import * as utils from "utils/utils";

export default React.memo((props: any): JSX.Element => {
	const { inputId, type, inputRef, focusSectionName, currFocusedSection, currFocusedHTMLElementRef, children, inputProps, ...rest } = props;
	const { id, ...inputPropsRest } = inputProps;

	return (
		<TextField
			inputRef={inputRef}
			size="small"
			type={props.type}
			fullWidth
			variant="outlined"
			inputProps={{
				id: props.inputId,
				...inputPropsRest,
			}}
			/*
                We are setting onMouseDown due to
                https://stackoverflow.com/a/57630197/5730790
            */
			onMouseDown={(event: any) => {
				event.preventDefault();
			}}
			onClick={(event: any) => {
				utils.focusIn(inputRef, focusSectionName, currFocusedSection, currFocusedHTMLElementRef);
				event.preventDefault();
			}}
			onFocus={() => {
				// if component is focused by any other possible mean -> we change the global ref
				/* 	if (currFocusedHTMLElementRef?.current) {
					console.log("inside textfield focus condition true : ", inputId);
					currFocusedSection.current = focusSectionName;
					currFocusedHTMLElementRef.current = inputRef;
				} */
			}}
			onBlur={() => {
				// if user clicks somewhere else and current focus is this card -> we forcefully bring back the focus to this card
				if (currFocusedSection?.current === focusSectionName && currFocusedHTMLElementRef?.current?.current?.id === inputId) {
					// Refer https://stackoverflow.com/questions/9884399/how-to-make-javascript-focus-method-work-in-onblur-event-for-input-text-box
					setTimeout(function () {
						currFocusedHTMLElementRef?.current?.current?.focus();
					}, 0);
				}
			}}
			{...rest}
		/>
	);
});
