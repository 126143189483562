import React from "react";
import CardActionArea, { CardActionAreaProps } from "@mui/material/CardActionArea";

export default React.memo((props: any): JSX.Element => {
	const { id, cardRef, focusSectionName, currFocusedSection, currFocusedHTMLElementRef, children, ...rest } = props;

	return (
		<CardActionArea
			id={id}
			ref={cardRef}
			/*
                We are setting onMouseDown due to
                https://stackoverflow.com/a/57630197/5730790
            */
			onMouseDown={(event: any) => {
				event.preventDefault();
			}}
			onFocus={() => {
				// if component is focused by any other possible mean -> we change the global ref
				// if (currFocusedHTMLElementRef?.current) {
				// 	currFocusedSection.current = focusSectionName;
				// 	currFocusedHTMLElementRef.current = cardRef;
				// }
			}}
			onBlur={() => {
				// if user clicks somewhere else and current focus is this card -> we forcefully bring back the focus to this card
				if (currFocusedSection?.current === focusSectionName && currFocusedHTMLElementRef?.current?.current?.id === id) {
					currFocusedHTMLElementRef?.current?.current?.focus();
				}
			}}
			{...rest}
		>
			{props.children}
		</CardActionArea>
	);
});
