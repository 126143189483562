import { DropDownDataType } from "types/common";
import makeStyles from "@mui/styles/makeStyles";

import constants from "constants/constants";

export const ListboxPropsSXObj = { backgroundColor: "grey.100", maxHeight: "100vh" };

const BORDER_WIDTH = 1.5;

export const useStyles = makeStyles({
	AutoCompleteFreeSoloInput_MainInput_inputBoxAllBorders: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				borderWidth: 1.5,
			},
			"&:hover fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
			},
			"&.Mui-focused fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				boxShadow: "0 5px 5px 0px #949595",
			},
		},

		"& .Mui-focused": {
			"& .MuiOutlinedInput-input": {
				backgroundColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderWidth: 0,
			},
		},
		"& .MuiOutlinedInput-input": {
			backgroundColor: "#F5F5F5 !important",
			paddingLeft: "5px !important",
			margin: "-5px !important",
			height: "35px",
			fontSize: "14px",
			borderWidth: 0,
		},
	},

	AutoCompleteFreeSoloInput_NonFormik_inputBoxAllBorders: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				borderWidth: 1.5,
			},
			"&:hover fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
			},
			"&.Mui-focused fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				boxShadow: "0 5px 5px 0px #949595",
			},
		},

		"& .Mui-focused": {
			"& .MuiOutlinedInput-input": {
				backgroundColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderWidth: 0,
			},
		},
		"& .MuiOutlinedInput-input": {
			backgroundColor: "#F5F5F5 !important",
			paddingLeft: "5px !important",
			margin: "0px !important",
			height: "25px",
			fontSize: "14px",
			borderWidth: 0,
		},
	},

	AutoCompleteFreeSoloInput_MultipleInput_inputBoxAllBorders_WithoutLeftMargin: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				borderWidth: 1.5,
			},
			"&:hover fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
			},
			"&.Mui-focused fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				boxShadow: "0 5px 5px 0px #949595",
			},
		},

		"& .Mui-focused": {
			"& .MuiOutlinedInput-input": {
				backgroundColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderWidth: 0,
			},
		},
		"& .MuiOutlinedInput-input": {
			backgroundColor: "#F5F5F5 !important",
			paddingLeft: "5px !important",
			margin: "-6px !important",
			height: "35px",
			fontSize: "14px",
			borderWidth: 0,
		},
	},
	AutoCompleteFreeSoloInput_MultipleInput_inputBoxAllBorders_WithLeftMargin: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
				borderWidth: 1.5,
			},
			"&:hover fieldset": {
				borderColor: constants.COLORS["BORDER_COLOR"],
			},
			"&.Mui-focused fieldset": {
				outline: "none",
				borderColor: constants.COLORS["BORDER_COLOR"],
				boxShadow: "0 5px 5px 0px #949595",
			},
		},

		"& .Mui-focused": {
			"& .MuiOutlinedInput-input": {
				backgroundColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderColor: constants.COLORS["INPUT_FOCUSED_COLOR"] + " !important",
				borderWidth: 0,
			},
		},
		"& .MuiOutlinedInput-input": {
			backgroundColor: "#F5F5F5 !important",
			paddingLeft: "5px !important",
			margin: "-6px !important",
			marginLeft: "1px !important",
			height: "35px",
			fontSize: "14px",
		},
	},
});
